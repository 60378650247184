import Head from 'next/head';
import Link from 'next/link';
import { NextPage } from 'next';
import React from 'react';

const Index: NextPage = () => {
  return (
    <div className="hero is-fullheight is-404">
      <Head>
        <title>Shift.ms - Hello tester!</title>
      </Head>
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-size-1">This is a non-production site 👻</h1>
          <h2 className="title is-size-2">
            This is why you can&#39;t see the home page
          </h2>
          <Link href="/home" className="button is-rounded is-primary">
            Go to the stream
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Index;
